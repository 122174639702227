import '../App.css';
import { Carousel } from 'react-bootstrap';
import { Clipboard, Archive, Briefcase } from 'react-feather';
import imgBusiness from '../img/illustrations/business_img.jpg';
import usa_flag from '../img/illustrations/flags/usa_flag.png';
import chile_flag from '../img/illustrations/flags/chile_flag.png';
import arg_flag from '../img/illustrations/flags/arg_flag.png';
import bsasTimes from '../img/illustrations/buenosAiresTimes.PNG';
import forbes from '../img/illustrations/forbes.png';
import ipro from '../img/illustrations/ipro.jpg';
import eleconomista from '../img/illustrations/eleconomista.JPG';

function Body() {

    //alert(document.lastModified);
    return (
        <>
            <section className="bg-white py-10">
                <div className="container px-5">
                    <div className="row gx-5 text-center">
                        <div className="col-lg-4 mb-5 mb-lg-0">
                            <div className="icon-stack icon-stack-xl bg-gradient-primary-to-secondary text-white mb-4"><Archive /></div>
                            <h3>Tax</h3>
                            <p className="mb-0">U.S. Tax services for individuals and businesses.</p>
                        </div>
                        <div className="col-lg-4 mb-5 mb-lg-0">
                            <div className="icon-stack icon-stack-xl bg-gradient-primary-to-secondary text-white mb-4"><Briefcase /></div>
                            <h3>Business</h3>
                            <p className="mb-0">Open your LLC and bank account in the United States from anywhere around the world.</p>
                        </div>
                        <div className="col-lg-4">
                            <div className="icon-stack icon-stack-xl bg-gradient-primary-to-secondary text-white mb-4"><Clipboard /></div>
                            <h3>Investment</h3>
                            <p className="mb-0">Invest in the U.S. Real Estate and stock markets from anywhere in the world.</p>
                        </div>
                    </div>
                </div>
                <div class="svg-border-rounded text-light">

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
                </div>
            </section>
            <section class="bg-light py-10">
                <div class="container px-5">
                    <div class="row gx-5 align-items-center justify-content-center">
                        <div class="col-md-9 col-lg-6 order-1 order-lg-0" data-aos="fade-right">
                            <div class="content-skewed content-skewed-right"><img class="content-skewed-item img-fluid shadow-lg rounded-3" src={imgBusiness} alt="..." /></div>
                        </div>
                        <div class="col-lg-6 order-0 order-lg-1 mb-5 mb-lg-0" data-aos="fade-left">
                            <div class="mb-5">
                                <h2>Some of the people we help…</h2>
                                <p class="lead">At primetaxonline.com we help individuals and businesses handle accounting matters in the U.S. – regardless of where they are in the world.</p>
                            </div>
                            <div class="row gx-5">
                                <div class="col-md-6 mb-4">
                                    <h6>U.S. Citizens</h6>
                                    <p class="mb-2 small">If you are U.S. citizen either living in the U.S. or living overseas; you must file taxes on worldwide income. We take care of the whole process for you and guarantee the best tax benefits.</p>
                                    <a class="small text-arrow-icon" href="/services">
                                        Learn More
                                        <i data-feather="arrow-right"></i>
                                    </a>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <h6>Non U.S. Citizens (Hablamos español!)</h6>
                                    <p class="mb-2 small mb-0">Are you a foreigner looking to invest, open a business and/ or work in the U.S.? We will analyze the best structure for you and prepare the necessary paperwork in order to move forward.</p>
                                    <a class="small text-arrow-icon" href="/services">
                                        Learn More
                                        <i data-feather="arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                            <div class="row gx-5">
                                <div class="col-md-6 mb-4">
                                    <h6>Business owners </h6>
                                    <p class="mb-2 small mb-0">From opening an LLC to web design, bookkeeping, marketing and tax; we have all the solutions so our clients can focus solely on doing what they love.</p>
                                    <a class="small text-arrow-icon" href="/services">
                                        Learn More
                                        <i data-feather="arrow-right"></i>
                                    </a>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <h6>Freelancers</h6>
                                    <p class="small mb-0">From digital nomads to self-employed individuals, we help freelancers structure their tax and business operations in a way that is tailored to their needs.</p>
                                    <a class="small text-arrow-icon" href="/services">
                                        Learn More
                                        <i data-feather="arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <hr class="m-0" />
            <section class="bg-light pt-10">
                <div class="container px-5">
                    <div class="text-center mb-5">
                        <h2>Our Services</h2>
                        <p class="lead">Everything you need, all in one place</p>
                    </div>
                    <div class="row gx-5 z-1">
                        <div class="col-lg-4 mb-5 mb-lg-n10" data-aos="fade-up" data-aos-delay="100">
                            <div class="card pricing h-100">
                                <div class="card-body p-5">
                                    <div class="text-center">
                                        <div class="badge bg-light text-dark rounded-pill badge-marketing badge-sm">Tax</div>
                                        <div class="pricing-price">
                                            <div className='starts_at_price'></div>
                                            
                                        </div>
                                    </div>
                                    <ul class="fa-ul pricing-list">
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Individual Income Tax return (Form 1040)</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Expat taxes</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Foreign Bank Account Report </span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Self – employment</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Foreign tax credit & treaties</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Interest and dividend earnings</span>
                                        </li>
                                        <li class="pricing-list-item" style={{padding:'15px'}}>
                                            <span class="text-dark">*Consult with an expert to determine your tax needs</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 mb-5 mb-lg-n10" data-aos="fade-up">
                            <div class="card pricing h-100">
                                <div class="card-body p-5">
                                    <div class="text-center">
                                        <div class="badge bg-primary-soft rounded-pill badge-marketing badge-sm text-primary">Business</div>
                                        <div class="pricing-price">
                                        <div className='starts_at_price'></div>
                                            
                                            <span class="pricing-price-period"></span>
                                        </div>
                                    </div>
                                    <ul class="fa-ul pricing-list">
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Bookkeeping</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Open/ renew your LLC</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Open a US bank account</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Business taxes</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Web design</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Marketing</span>
                                        </li>
                                        <li class="pricing-list-item" style={{padding:'15px'}}>
                                            <span class="text-dark">*Consult with an expert to tailor your business budget</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 mb-lg-n10" data-aos="fade-up" data-aos-delay="100">
                            <div class="card pricing h-100">
                                <div class="card-body p-5">
                                <div class="text-center">
                                        <div class="badge bg-primary-soft rounded-pill badge-marketing badge-sm text-primary">Investment</div>
                                        <a href='/appointments'><div className='investment_btn_card' >FREE Investment consulting</div></a>
                                    </div>
                                    <ul class="fa-ul pricing-list">
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Real estate market</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Invest in the U.S. Stock market</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Investment VISA advisory</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Short, medium and long term investment plans </span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Small capital investments</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Large capital investments</span>
                                        </li>
                                        <div>*Consult an expert on the investment that best fits your personality.</div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
            <section class="bg-dark py-10">
                <div class="container px-5">
                    <div class="row gx-5 my-10">
                        <div class="col-lg-6 mb-5">
                            <div class="d-flex h-100">
                                <div class="icon-stack flex-shrink-0 bg-teal text-white"><i class="fas fa-question"></i></div>
                                <div class="ms-4">
                                    <h5 class="text-white">What are U.S. taxes?</h5>
                                    <p class="text-white-50">If you’ve worked in the U.S., own a U.S. business or are a U.S. citizen; you must declare your earnings/ losses to the United States tax authorities (IRS).</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 mb-5">
                            <div class="d-flex h-100">
                                <div class="icon-stack flex-shrink-0 bg-teal text-white"><i class="fas fa-question"></i></div>
                                <div class="ms-4">
                                    <h5 class="text-white">How can I know if the services apply to me?</h5>
                                    <p class="text-white-50">If you have any doubts, you can easily speak to one of our representatives, for free, with zero compromise to determine if we can assist you with anything.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 mb-5 mb-lg-0">
                            <div class="d-flex h-100">
                                <div class="icon-stack flex-shrink-0 bg-teal text-white"><i class="fas fa-question"></i></div>
                                <div class="ms-4">
                                    <h5 class="text-white">Do I need to go to the U.S. at any time?</h5>
                                    <p class="text-white-50">All our services are online and can be done from overseas. No need to go at any point. Some specific services will require an address and/ or representative in the U.S. – which we have available.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="d-flex h-100">
                                <div class="icon-stack flex-shrink-0 bg-teal text-white"><i class="fas fa-question"></i></div>
                                <div class="ms-4">
                                    <h5 class="text-white">Where can I find more information?</h5>
                                    <p class="text-white-50">If there is something you do not find directly on our site, feel free to write to us, send a Whatsapp or set up an audio/ video call.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row gx-5 justify-content-center text-center">
                        <div class="col-lg-8">
                            <div class="badge bg-transparent-light rounded-pill badge-marketing mb-4">Get Started</div>
                            <h2 class="text-white">Save time with primetaxonline.com</h2>
                            <p class="lead text-white-50 mb-5">Have all of your individual, business and financial matters solved so you can invest your time in growing in the areas where your creativity and passion most demand it.</p>
                            <a class="btn btn-teal fw-500" href="/services">View all services and prices</a>
                        </div>
                    </div>
                </div>
                <div class="svg-border-rounded text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
                </div>
            </section>
            <section class="bg-white pt-10">
                <div class="container px-5">
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div style={{fontSize:'30px'}}>Featured in</div>
                        </div>
                    </div>
                    <div className='row' style={{paddingTop:'10px',textAlign:'center'}}>
                        <div className='col-lg-6' style={{textAlign:'center'}}>
                            <a href='https://www.perfil.com/noticias/amp/economia/todo-lo-que-tenes-que-saber-sobre-abrir-una-empresa-en-los-estados-unidos.phtml'><div style={{paddingTop:'60px'}}><img height={40} src='https://www.perfil.com/img/logo-perfil-header.png' /></div>
                            <div style={{padding:'15px'}}>Todo lo que tenés que saber sobre abrir una empresa en los Estados Unidos</div>
                            </a>
                        </div>
                        <div className='col-lg-6' style={{textAlign:'center'}}>
                            <a href='https://www.batimes.com.ar/news/economy/tax-benefits-you-should-know-as-an-american-expat-living-in-buenos-aires.phtml'><div style={{paddingTop:'60px'}}><img height={40} src={bsasTimes} /></div>
                            <div style={{padding:'15px'}}>Tax benefits you should know as an American Expat living abroad</div>
                            </a>
                        </div>
                        <div className='col-lg-6' style={{textAlign:'center'}}>
                            <a href='https://www.forbesargentina.com/columnistas/trump-vs-harris-puede-esperar-politica-economica-fiscal-n59595'><div style={{paddingTop:'60px'}}><img height={40} src={forbes} /></div>
                            <div style={{padding:'15px'}}>Trump vs Harris: qué se puede esperar en política económica y fiscal</div>
                            </a>
                        </div>
                        <div className='col-lg-6' style={{textAlign:'center'}}>
                            <a href='https://www.iprofesional.com/impuestos/416584-impuestos-estados-unidos-como-afectara-argentinos-si-gana-donald-trump-o-kamala-harris-elecciones-noviembre-2024'><div style={{paddingTop:'60px'}}><img height={40} src={ipro} /></div>
                            <div style={{padding:'15px'}}>Propuestas impositivas en EE.UU.: cómo afectarán Trump o Harris los negocios de los argentinos</div>
                            </a>
                        </div>
                        <div className='col-lg-12' style={{textAlign:'center'}}>
                            <a href='https://eleconomista.com.ar/internacional/la-fed-baja-tasas-nuevamente-que-significa-esto-mercados-emergentes-como-argentina-n79387#google_vignette'><div style={{paddingTop:'60px'}}><img height={40} src={eleconomista} /></div>
                            <div style={{padding:'15px'}}>La Fed baja las tasas, nuevamente. ¿Qué significa esto para mercados emergentes como Argentina?</div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section class="bg-white pt-10">
                <div class="container px-5">

                    <div className='row'>
                        <div className="col-lg-6" style={{ zIndex: '1', padding:'5px' , verticalAlign: 'middle' , justifyContent:'center'}}>
                            <a class="card text-decoration-none h-100 lift" href="#!" style={{verticalAlign: 'middle' }}>
                                <div class="card-body py-5">
                                    <div class="align-items-center">
                                        <div class="icon-stack icon-stack-xl bg-primary text-white flex-shrink-0" style={{backgroundImage: `url(${arg_flag})`, backgroundSize: 'cover', backgroundPosition: 'center'}}></div>
                                        <div class="ms-4" style={{padding:'10px'}}>
                                            <h5 class="text-primary">Alex B. - American Expat living in Argentina.</h5>
                                            <p class="card-text text-gray-600" style={{ overflowY: "auto" }}>"After many years of stressing over my U.S. taxes while living in Argentina, I finally found Primetaxonline who quickly and painlessly helped me navigate all the requirements and get all my taxes in order quickly. All for a reasonable fee. Thanks for helping reduce my level of anxiety!!!"</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-6" style={{ zIndex: '1' }}>
                            <div className='row'>
                            <div className='col-lg-12' style={{padding:'5px'}}> 
                                <a class="card text-decoration-none h-100 lift" href="#!">
                                                <div class="card-body py-5">
                                                    <div class="d-flex align-items-center">
                                                        <div class="icon-stack icon-stack-xl bg-primary text-white flex-shrink-0" style={{backgroundImage: `url(${usa_flag})`, backgroundSize: 'cover'}}></div>
                                                        <div class="ms-4">
                                                            <h5 class="text-primary">Nicolas C. - American Citizen living in the U.S.</h5>
                                                            <p class="card-text text-gray-600" style={{}}>"Very simple process. They were very informative and helped every step along the way"</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                </div>
                                <div className='col-lg-12' style={{padding:'5px'}}>
                                <a class="card text-decoration-none h-100 lift" href="#!">
                                                <div class="card-body py-5">
                                                    <div class="d-flex align-items-center">
                                                    <div class="icon-stack icon-stack-xl bg-primary text-white flex-shrink-0" style={{backgroundImage: `url(${chile_flag})`, backgroundSize: 'cover'}}></div>
                                                        <div class="ms-4">
                                                            <h5 class="text-primary">Barbara S. - American Expat living in Chile.</h5>
                                                            <p class="card-text text-gray-600" style={{overflowY:"auto"}}>"I have been doing my taxes with them for years. Highly recommended."</p>
                                                        </div>
                                                    </div>
                                                </div>
                                </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12" style={{ zIndex: '1', padding:'5px' , verticalAlign: 'middle' , justifyContent:'center'}}>
                            <a class="card text-decoration-none h-100 lift" href='/comments' style={{verticalAlign: 'middle' }}>
                                <div class="card-body py-5">
                                    <div class="align-items-center" style={{color:'green'}}>
                                        <a href='/comments'>See more</a>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>

                </div>
                <div class="svg-border-rounded text-light" style={{zIndex:'-1'}}>

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
                </div>
            </section>
            <section class="bg-light py-10">
                <div class="container px-5 mt-5">
                    <div class="row gx-5 align-items-center">
                        <div class="col-lg-6">
                            <h4>Ready to get started?</h4>
                        </div>
                        <div class="col-lg-6 text-lg-end">
                            <a class="btn btn-primary fw-500 me-3 my-2" href="/contact">Contact us now</a>
                            <a class="btn btn-white fw-500 my-2 shadow" href="/appointments">Schedule a free call</a>
                        </div>
                    </div>
                </div>
            </section>
            <hr class="m-0" />

        </>
    )
}

export default Body;